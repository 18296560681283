@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

body {
  --h: 206;
  --s: 42%;
  --l: 56%;
  --dark: hsl(var(--h) var(--s) 10%);
  --dark-hover: hsl(var(--h) var(--s) 25%);
  --light: hsl(var(--h) var(--s) 99%);

  --gap: 1.5rem;
  background: var(--bg);
  display: grid;
  margin: unset;
}

h1 {
  color: var(--light);
  display: flex;
  flex-flow: column;
  font-size: clamp(4rem, 1.1429rem + 9.1429vw, 8rem);
  line-height: 0.85;
  margin: calc(2 * var(--gap)) auto;
  width: 50%;
}

h1 span:last-of-type {
  align-self: flex-end;
  color: var(--dark);
}

/* Circle of Ffths */
.cf {
  aspect-ratio: 1;
  margin-block: var(--gap) 0;
  width: calc(85vw - (2 * var(--gap)));
}
.cf-arcs {
  transform: rotate(-15deg);
  transform-origin: 50% 50%;
}
.cf-arcs path {
  fill: var(--dark);
  stroke: hsl(var(--h), var(--s), var(--l));
}
.cf-arcs path:hover {
  fill: var(--dark-hover);
}
.cf-text-inner,
.cf-text-middle {
  fill: var(--light);
  font-family: ui-sans-serif, system-ui, sans-serif;
  font-weight: 500;
  letter-spacing: -0.065em;
  pointer-events: none;
  text-anchor: middle;
}
.cf-text-inner {
  font-size: 0.4em;
}
.cf-text-middle {
  font-size: 0.6em;
}

/* Flat and Sharp Positions */
.cf-flat-1 {
  transform: translate(10px, -2.5px);
}
.cf-flat-2 {
  transform: translate(13px, -7.5px);
}
.cf-flat-3 {
  transform: translate(16px, -1px);
}
.cf-flat-4 {
  transform: translate(19px, -5.5px);
}
.cf-flat-5 {
  transform: translate(22px, 1px);
}
.cf-sharp-1 {
  transform: translate(10px, -7.5px);
}
.cf-sharp-2 {
  transform: translate(13px, -3px);
}
.cf-sharp-3 {
  transform: translate(16px, -9px);
}
.cf-sharp-4 {
  transform: translate(19px, -4.5px);
}
.cf-sharp-5 {
  transform: translate(22px, 0.5px);
}
.cf-sharp-6 {
  transform: translate(25px, -6px);
}

.cf use {
  pointer-events: none;
}
.cf [baseline-shift="super"] {
  font-size: 80%;
}
